<template>
  <RecordViewWrapper>
    <sdPageHeader :title="pageState.title">
      <template #subTitle>
        <router-link :to="'/kpi/kpi-korporat/' + dateState.month + '/' + dateState.year">
          <sdButton class="sDash_form-action__btn" type="primary" size="default" htmlType="button">
            Kembali
          </sdButton>
        </router-link>
        <!-- </template> -->
        <!-- <template #buttons> -->
        <!--   <div class="search-box"> -->
        <!--     <span class="search-icon"> -->
        <!--       <sdFeatherIcons type="search" size="14" /> -->
        <!--     </span> -->
        <!--     <a-input -->
        <!--       @change="handleSearch" -->
        <!--       v-model:value.trim="formState.searchItem" -->
        <!--       type="text" -->
        <!--       name="recored-search" -->
        <!--       placeholder="Search Here" -->
        <!--     /></div>> -->
      </template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table :pagination="false" :dataSource="dataSource" :columns="tableState.columns" />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { DataService } from '@/config/dataService/dataService';

const numberFormat = new Intl.NumberFormat('id');

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const { params } = useRoute();

    const modalState = reactive({
      isLoading: false,
      visible: false,
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const dateState = reactive({
      month: params.month,
      year: params.year,
    });

    const pageState = reactive({
      title: 'Indikator Kinerja',
    });

    const tableState = reactive({
      columns: [],
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    onMounted(() => {
      DataService.get('kpi-korporat/' + params.id).then(response => {
        pageState.title = response.data.data.name;
      });

      const data = {
        url: 'kpi-korporat-table',
        id: params.id,
      };

      dispatch('axiosSingleDataGet', data).then(() => {
        tableState.columns = [
          {
            title: 'Keterangan',
            dataIndex: 'description',
            key: 'description',
          },
        ];

        if (crud.value.months) {
          crud.value.months.forEach((data, index) => {
            tableState.columns.push({
              title: data,
              dataIndex: 'value_' + index,
              key: 'value_' + index,
              align: 'right',
            });
          });
        }
      });
    });

    const handleDelete = (id, kpi_korporat, month) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus target ${kpi_korporat} ${months[month]}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'target-kpi-korporat',
            getData: () => {
              dispatch('axiosCrudGetData', 'target-kpi-korporat');
            },
          });
        },
      });
    };

    const handleApprove = (id, kpi_korporat, month) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve target ${kpi_korporat} ${months[month]}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-target-kpi-korporat',
            getData: () => {
              dispatch('axiosCrudGetData', 'target-kpi-korporat');
            },
          });
        },
      });
    };

    const handleUnapprove = id => {
      formModalState.id = id;
      modalState.visible = true;
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() =>
      crud.value.variables && crud.value.variables.length
        ? crud.value.variables.map((data, key) => {
            let dataTables = {
              key: key + 1,
              description: data.name,
            };

            for (let ii = 0; ii < 12; ii++) {
              dataTables['value_' + ii] = data['value_' + ii] ? numberFormat.format(data['value_' + ii]) : '-';
            }

            return dataTables;
          })
        : [],
    );

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'target-kpi-korporat', filter: formState.searchItem });
    };

    const showModal = () => {
      modalState.visible = true;
    };

    const handleCancel = () => {
      modalState.visible = false;
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-target-kpi-korporat/' + formModalState.id, { alasan: formModalState.alasan }).then(
        () => {
          modalState.visible = false;
          modalState.isLoading = false;
          formModalState.id = null;
          formModalState.alasan = '';

          dispatch('axiosCrudGetData', 'target-kpi-korporat');
        },
      );
    };

    return {
      formState,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleApprove,
      handleUnapprove,
      handleSearch,
      labelCol,
      wrapperCol,
      modalState,
      showModal,
      handleCancel,
      doUnapprove,
      formModalState,
      modalRules,
      pageState,
      tableState,
      dateState,
    };
  },
};

export default ViewPage;
</script>
